import { PageRoute } from '@/generated/enums'
import { useIsEmployeeView } from '@/hooks/id_token_claims'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Layout from '../components/layout'
import Reports from './reports'
import Web2 from './web2'

const Index = () => {
  const router = useRouter()
  const [isEmployeeView] = useIsEmployeeView()

  useEffect(() => {
    if (isEmployeeView) {
      // Redirect to Unified Alerts page on initial login
      router.replace(PageRoute.UNIFIED_ALERTS + '/domains')
    }
  }, [isEmployeeView])

  return <Web2 />
}

Index.getLayout = function getLayout(page) {
  return (
    <Layout>
      <Reports component={page} />
    </Layout>
  )
}

export default Index
